.contact-form-container {
  font-family: var(--font-family);
  width: 100%;
  max-width: 60%;
  margin: 2rem auto;
  padding: 20px;
  background: var(--color-blog);
  color: var(--color-text);
  border-radius: 10px;
}

.contact-form-container h2 {
  text-align: center;
}

.contact-form {
  display: grid;
  gap: 10px;
}

.form-group {
  display: grid;
  gap: 5px;
}

label {
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

button {
  padding: 10px;
  background: #FF4820;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--color-text);
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .contact-form-container {
      max-width: 80%;
      padding: 10px;
  }

  input,
  textarea {
      padding: 6px;
  }

  button {
      padding: 8px;
  }
}

@media screen and (max-width: 480px) {
  .contact-form-container {
      max-width: 90%;
  }

  input,
  textarea {
      padding: 4px;
  }

  button {
      padding: 6px;
  }
}