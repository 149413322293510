.marshalPfluger__resume {
    flex: 1;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;

}

.marshalPfluger__resume h1 {
    font-family: var(--font-family);
    color: var(--color-text);
    text-align: center;
    font-weight: 800;
    font-size:  62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.marshalPfluger__resume-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 20px;
    max-width: 100%;
    margin: 0 auto;
}

.resume-item {
    cursor: pointer;
    background: var(--color-blog);
    width: 100%;
    height: 100%;
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    }

    .resume-item img {
    width: 100%;
    height: 90%;
    object-fit: cover;
    border-radius: 10px;
    }

    /* Media queries for responsive design */
@media screen and (max-width: 1200px) {
    .resume-item {
        width: calc(100% / 4);
    }
}

@media screen and (max-width: 992px) {
    .resume-item {
        width: calc(100% / 3);
    }
}

@media screen and (max-width: 768px) {
    .resume-item {
        width: calc(100% / 2);
    }
}

@media screen and (max-width: 576px) {
    .resume-item {
        width: 100%;
    }
}