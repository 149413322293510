.marshalPfluger__about {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: var(--color-footer);
  border-radius: 10px;

  /* ff 3.6+ */
  background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

  /* opera 11.10+ */
  background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

  /* ie 10+ */
  background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}


.marshalPfluger__about-feature {
  display: flex;
  flex-direction: row;
}

.marshalPfluger__about-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 2rem;
}

.marshalPfluger__about-container .marshalPfluger__features-container__feature {
  flex: 1;
  margin: 1rem;
  min-width: 210px;
  display: unset;
  flex-direction: column;
}

.marshalPfluger__about-container .marshalPfluger__features-container_feature-text {
  margin-top: 0.5rem;
}

.marshalPfluger__about img {
  max-width: 200px;
  max-height: 300px;
  border-radius: 10px;
  margin-bottom: 2px;
}

@media screen and (max-width: 850px) {
  .marshalPfluger__about {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0rem;
  }

  .marshalPfluger__about-heading p {
      margin-top: 1rem;
  }
}

@media screen and (max-width: 650px) {

  .marshalPfluger__about-feature .marshalPfluger__features-container__feature {
      flex-direction: column;
  }

.marshalPfluger__about-feature img {
  max-height: 100px;
  max-width: 100px;
}
  .marshalPfluger__about-feature .marshalPfluger__features-container_feature-text {
      margin-top: 0.5rem;
  }

  .marshalPfluger__about-heading h1 {
      font-size: 28px;
      line-height: 40px;
  }
}

@media screen and (max-width: 350px) {

  .marshalPfluger__about-container .marshalPfluger__features-container__feature {
      margin: 1rem 0;
      min-width: 100%;
  }
}